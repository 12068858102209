






































































































































































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';
import RsButton from '@/shared/components/buttons/button.vue';
import {Icons} from '@/shared/components/icon/icons';
import {Action, Getter} from 'vuex-class';
import {JobsActions} from '@/modules/dashboard/modules/jobs/store/actions';
import {JobsGetters} from '@/modules/dashboard/modules/jobs/store/getters';
import DashboardModule from '@/modules/dashboard';
import JobsModule from '@/modules/dashboard/modules/jobs';
import {IApiJob} from '@/shared/models/IApiJob';
import RsLoading from '@/shared/components/loading/loading.vue';
import {DATE_SERVICE} from '@/shared/services/date.service';
import RsModal from '@/shared/components/modal/modal.vue';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {JobBenefitsData} from '@/modules/dashboard/modules/jobs/constants/JobBenefitsData';
import {API_SERVICE} from '@/shared/api/api.service';
import {CodetableResult, Codetables, CompanySize} from '@/shared/api/modules/codetable.api.service';
import {IApiJobSectionData} from '@/shared/models/IApiJobData';
import { timeout } from '@/shared/services/common.utils';

@Component({
  components: {RsModal, RsLoading, RsButton, RsIcon}
})
export default class JobsIndex extends Vue {
  @Action(JobsActions.GET_JOB, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) getJob: (jobId: string) => Promise<IApiJob>;
  @Action(JobsActions.APPLY_FOR_JOB, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) applyForJob: (jobId: string) => Promise<void>;

  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;
  @Getter(JobsGetters.GET_JOB, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) job: IApiJob;

  JobsRouter = JobsRouter;
  Icons = Icons;
  JobBenefitsData = JobBenefitsData;

  codetables: CodetableResult = {}

  id: string;
  loading = false;

  sending = {
    apply: false
  }

  applied = true;

  get sortedSections(): IApiJobSectionData[] {
    return this.job.data.textSections?.sort((a, b) => a.order - b.order);
  }

  get isNew(): boolean {
    if (this.job) {
      return DATE_SERVICE.diff(this.job.createdAt, 'day') > -14
    }
    return false;
  }

  get companySizeLabel(): CompanySize | undefined {
    if (this.codetables?.company_sizes) {
      return (this.codetables.company_sizes as CompanySize[]).find((companySize) => companySize.name === this.job.company.data.headcount);
    }
  }

  get countryMatch(): boolean {
    if (!this.job.data.worldwide && this.hasLocationRequirements && this.user.country) {
      return this.locationRequirements.includes(this.user.country.trim());
    }

    return true;
  }

  get timezoneMatch(): boolean {
    if (!this.job.data.worldwide && this.user.properties.TALENT_WORK_TIMEZONE && this.job.data.timezoneFrom && this.job.data.timezoneTo) {
      try {
        const fromTz = parseInt(this.job.data.timezoneFrom.replace('UTC', ''));
        const toTz = parseInt(this.job.data.timezoneTo.replace('UTC', ''));
        const userTz = parseInt(this.user.properties.TALENT_WORK_TIMEZONE.replace('UTC', ''));
        return fromTz <= userTz && userTz <= toTz;
      } catch (e) {
        return true;
      }
    }

    return true;
  }

  get hasLocationRequirements(): boolean {
    return this.job.data.countries !== undefined && this.job.data.countries.length > 0;
  }

  get locationRequirements(): string[] {
    if (this.hasLocationRequirements) {
      return this.job.data.countries as string[];
    }

    return [];
  }

  async apiApply() {
    this.sending.apply = true;
    try {
      await this.applyForJob(this.id);
      this.$track.sendEvent('JobApply', {id: this.id})
      this.applied = true;
      // @ts-ignore
      this.$rs.alert.open({
        type: 'success',
        icon: Icons.SUCCESS_CIRCLE,
        title: 'Your application has been successfully sent',
        body: `${this.job.company.data.name} has been notified of your application. If they find your experience interesting, they will reach out to you in few days. `,
        confirm: {
          text: 'Got it',
          action: () => {
          }
        },
      });
    } catch (e) {
      // @ts-ignore
      this.$rs.toast({
        title: 'Error applying for a job',
        body: 'There was an error applying for job. Please try again later.'
      });
    } finally {
      this.sending.apply = false;
    }
  }

  async apply() {
    if (!this.applied) {
      let title: string | null = null;
      let warningText: string | null = null;

      if (!this.countryMatch && !this.timezoneMatch) {
        title = 'Job requirements not met';
        warningText = `
         This Job Position requires candidates to be from <strong>${this.locationRequirements.join(' or ')}</strong> and within the folowing timezone range <strong>${this.job.data.timezoneFrom} to ${this.job.data.timezoneTo}</strong>.
         <br/>
         <br/>
         Your country and timezone are currently set to <strong>${this.user.country} and ${this.user.properties.TALENT_WORK_TIMEZONE}</strong>.
         <br/>
         There is a high chance your application will not be considered if you don't match the requirements.
         <br/>
         <br/>
         Do you still want to apply?
`;
      } else if (!this.countryMatch) {
        title = 'Location requirements not met';
        warningText = `
          This Job Position requires candidates to come from the following ${this.locationRequirements.length > 1 ? 'countries' : 'country'}: <strong>${this.locationRequirements.join(' or ')}</strong>.
          <br/>
          Your country is currently set to <strong>${this.user.country}</strong>.
          <br/>
          <br/>
          There is a high chance your application will not be considered if you don't match the requirements.
          <br/>
          <br/>
          Do you still want to apply?
          `;
      } else if (!this.timezoneMatch) {
        title = 'Timezone requirements not met';
        warningText = `
          This Job Position requires candidates to be within the following timezone range: <strong>${this.job.data.timezoneFrom} to ${this.job.data.timezoneTo}</strong>.
          <br/>
          Your current timezone is set to <strong>${this.user.properties.TALENT_WORK_TIMEZONE}</strong>.
          <br/>
          <br/>
          There is a high chance your application will not be considered if you don't match the requirements.
          <br/>
          <br/>
          Do you still want to apply?
          `;
      }

      if (title && warningText) {
        const confirmed = await new Promise<boolean>((resolve => {
          // @ts-ignore
          this.$rs.alert.open({
            title,
            icon: Icons.INFO,
            iconType: 'warning',
            body: warningText,
            confirm: {
              text: 'Submit application',
              action: () => {
                resolve(true);
              },
            },
            cancel: {
              text: 'Cancel',
              action: () => {
                resolve(false);
              },
            },
          });
        }));
        if (confirmed) {
          await timeout(300);
          await this.apiApply();
        }
      } else {
        await this.apiApply();
      }
    }
  }


  formatSalary(salary: number) {
    if (salary % 1000 > 0) {
      return `${Math.floor(salary / 1000)}.${Math.floor(salary % 1000 / 100)}`
    }
    return Math.floor(salary / 1000);
  }

  async mounted() {
    this.id = this.$route.params.id;
    this.$track.sendEvent('JobSingle', {isPublic: false, id: this.id})
    if (!this.job || this.job.id !== this.id) {
      this.loading = true;
    }
    this.getJob(this.id)
      .then(job => {
        this.applied = job.applied || false;
      })
      .finally(() => {
        this.loading = false;
      })
    this.codetables = await API_SERVICE.codetable.getCodetables(Codetables.COMPANY_SIZES);
  }
}
